<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              class="mr-3"
              v-on="on"
            >
              mdi-help-circle-outline
            </v-icon>
          </template>
          Usługi dodatkowe z domyślnie aktywną wartością będą włączone na dokumencie wydania.
        </v-tooltip>

        {{ $t('courierSet.sideNavigation.additionalServices').toUpperCase() }}
      </v-card-title>
      <v-card-text>
        <v-row class="pb-5">
          <v-divider />
        </v-row>

        <v-row>
          <v-col>
            <div
              v-for="serv in courierAddtionalServices"
              :key="serv.id"
              class="ma-3"
            >
              <service-field
                :id="serv.id"
                :label="serv.name"
                :messages="serv.description"
                :remark="serv.remark"
                :code="serv.serviceId"
                :selected-service="isSelected(serv)"
                :active-service="isActive(serv)"
                :visible-service="isVisible(serv)"
                :parameters="serv.parameters"
                @@activate="activate"
                @@deactivate="deactivate(serv)"
                @@update="updateService"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { dhlAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/DHLExpress/AddtionalServices';
import ServiceField from '@/components/inputs/ServiceField.vue';
import { mapGetters } from 'vuex';
import additionalServicesMixin from '@/mixins/additionalServicesMixin';

export default {
  name: 'AdditionalServices',
  components: { ServiceField },
  mixins: [additionalServicesMixin],
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: null,
    id: null,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
  },
  methods: {
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        parameters: {
          clientEntityState: this.isNew || !this.id ? 4 : state,
          id: this.id,
          tenantId: this.oidcUser.tenantId,
          services: this.additionalServices,
        },
      };
      this.$emit('@input', serviceParameters);
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
::v-deep .v-input--selection-controls {
  padding-top: 0px;
}
.additional-inputs {
  padding: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
}
</style>
