<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.parcelContent.title').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="productCode"
              :title="$t('courierSet.productCode')"
              rules="max:100"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    TextField,
  },
  props: {
    isNew: { type: Boolean, default: false },
  },
  data: () => ({
    productCode: '',
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
  },
  mounted() {
    if (!this.isNew) {
      this.getDataFromStore();
    }
  },
  methods: {
    update() {
      const parameters = {
        clientEntityState: this.isNew ? 4 : 3,
        productCode: this.productCode,
      };
      this.$emit('@input', parameters);
    },
    getDataFromStore() {
      const { dhlExpressDefaultValues } = this.getConfigurationSet();
      this.productCode = dhlExpressDefaultValues.productCode;
    },
  },
};
</script>

<style scoped lang="scss">
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
