<template>
  <configuration-set
    v-if="confData"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
        @@input="update"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
        @@input="update"
      />

      <AdditionalPayments
        v-show="currentComponent === 'AdditionalPayments'"
        :is-new="isNew"
        @@input="update"
      />

      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
        @@input="update"
      />
    </template>
  </configuration-set>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import configurationMixin from '@/mixins/configurationMixin';
import ServiceAuthorization from '@/views/Speditors/DHLExpress/Forms/ServiceAuthorization.vue';
import AdditionalServices from '@/views/Speditors/DHLExpress/Forms/AdditionalServices.vue';
import AdditionalPayments from '@/views/Speditors/DHLExpress/Forms/AdditionalPayments.vue';
import ContentAndComments from '@/views/Speditors/DHLExpress/Forms/ContentAndComments.vue';
import { DHLExpress } from '@/constants/Speditors/DHLExpress/configuration/DHLExpress';

export default {
  name: 'DHLExpress',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalPayments,
    AdditionalServices,
    ContentAndComments,
  },
  mixins: [configurationMixin],
  data: () => ({
    confData: null,
    isNew: false,
    speditor: 'DHLExpress',
    organizationId: null,
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.sideNavigation.authorizationData' },
      { code: 'AdditionalServices', name: 'courierSet.sideNavigation.additionalServices' },
      { code: 'AdditionalPayments', name: 'courierSet.sideNavigation.paymentsSettings' },
      { code: 'ContentAndComments', name: 'courierSet.sideNavigation.contentAndComments' },
    ],
  }),
  computed: {
    getUrlAddress() {
      return 'https://express.api.dhl.com/mydhlapi/test';
    },
  },
  methods: {
    update(componentData) {
      switch (this.currentComponent) {
        case 'ServiceAuthorization':
          this.confData.configurationName = componentData.configurationName;
          this.confData.id = componentData.id;
          this.confData.password = componentData.password;
          this.confData.url = componentData.url;
          this.confData.apiKey = componentData.apiKey;
          this.confData.apiSecret = componentData.apiSecret;
          break;
        case 'AdditionalServices':
          this.confData.additionalServices = componentData.parameters;
          this.confData.dhlExpressDefaultValues.insurance.amount = componentData.amount;
          this.confData.dhlExpressDefaultValues.insurance.clientEntityState =
            componentData.clientEntityState;
          break;
        case 'AdditionalPayments':
          if (this.confData.dhlExpressDefaultValues.accounts.length) {
            this.confData.dhlExpressDefaultValues.accounts[0].clientEntityState =
              componentData.clientEntityState;
            this.confData.dhlExpressDefaultValues.accounts[0].number = componentData.number;
            this.confData.dhlExpressDefaultValues.accounts[0].typeCode = componentData.typeCode;
          } else {
            this.confData.dhlExpressDefaultValues.accounts.push({
              clientEntityState: componentData.clientEntityState,
              number: componentData.number,
              typeCode: componentData.typeCode,
              tenantId: '',
            });
          }
          break;
        case 'ContentAndComments':
          this.confData.dhlExpressDefaultValues.clientEntityState = componentData.clientEntityState;
          this.confData.dhlExpressDefaultValues.productCode = componentData.productCode;
          break;
        default:
          break;
      }
    },
    setNewConfiguration() {
      DHLExpress.tenantId = this.oidcUser.tenantId;
      DHLExpress.id = uuidv4();
      DHLExpress.url = this.getUrlAddress;
      this.setConfigSet({ response: DHLExpress });
    },
  },
};
</script>
